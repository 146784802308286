<template>
  <div v-loading="isCreatePage" class="custom--assembly-form">
    <el-row :gutter="10">
      <el-col :span="14">

        <div style="margin-bottom: 10px;color: #fc4b4b">按钮条件匹配</div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="180px" size="small">

          <div v-for="item in originalCreateFormData" :key="item.prop">
            <el-form-item v-if="isShowFormItem(item)" :prop="item.prop">
              <template #label>
                <div style="display: flex;flex-direction: row;align-items: center;">
                  <span style="margin-right: 10px">{{ item.label }}</span>
                  <el-tooltip v-if="item.tooltipText" effect="dark" :content="item.tooltipText" placement="top">
                    <i class="el-icon-warning"/>
                  </el-tooltip>
                </div>
              </template>

              <assembly-form-item v-model="ruleForm[item.prop]" :item="item" :style="item.style"/>
              <!--          <el-button v-if="item.isShowDeleteBtn" style="margin-left: 20px" @click="onDeleteFormItem(item)">删除</el-button>-->
            </el-form-item>
          </div>
          <!--          <el-form-item label="唯一配置">-->
          <!--            <router-link to="/crm/possess">-->
          <!--              <el-button type="success" size="mini">查看配置</el-button>-->
          <!--            </router-link>-->

          <!--          </el-form-item>-->
          <!--          <el-form-item label="顾问配置">-->
          <!--            <router-link to="/Configure">-->
          <!--              <el-button type="danger" size="mini">查看配置</el-button>-->
          <!--            </router-link>-->
          <!--          </el-form-item>-->
        </el-form>
      </el-col>

      <el-col :span="10">
        <div style="margin-bottom: 10px;color: #fc4b4b">绑定可见部门（控制人员可用权限）</div>

        <el-tree
            ref="deptForm"
            :data="deptTree"
            show-checkbox
            node-key="id"
            :default-checked-keys="checkedDept"
            :default-expand-all="true"
            :props="{
              children: 'children',
              label: 'title'
            }">
        </el-tree>
      </el-col>
    </el-row>


    <el-divider></el-divider>
    <div style="margin-bottom: 10px;color: #fc4b4b">动态配置项</div>
    <el-form :model="ruleForm2" :rules="rules2" ref="ruleForm2" label-width="150px" size="small">

      <el-form-item label="添加配置项">
        <el-select size="small" v-model="selectCreateForm" placeholder="请选择表单项">
          <el-option
              v-for="item in selectCreateFormOptionsArr"
              :key="item.name"
              :label="item.label"
              :value="item.name">
          </el-option>
        </el-select>

        <el-button style="margin-left: 10px" size="small" @click="addFormItem(selectCreateForm)">添加</el-button>
      </el-form-item>

      <div v-for="item in originalCreateFormData2" :key="item.id">
        <el-form-item v-if="isShowFormItem(item)" :label="item.label">

          <assembly-form-item v-model="ruleForm2[item.id]" :item="item" :style="item.style"/>

          <el-button v-if="item.isShowDeleteBtn" style="margin-left: 20px" @click="onDeleteFormItem(item)">删除
          </el-button>
        </el-form-item>
      </div>


      <el-form-item>
        <el-button type="primary" @click="submitForm">保存</el-button>
      </el-form-item>
    </el-form>

  </div>
</template>

<script>
import AssemblyFormItem from "@/components/formItem/assembly-form-item.vue";
import * as receiveApi from "@/api/system/receive";
import * as possApi from "@/api/crm/possess";
import * as tagsApi from "@/api/crm/tags";

function guid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export default {
  components: {AssemblyFormItem},
  data() {
    return {
      // 页面是否正在构建中
      isCreatePage: false,
      /*
      *
      * prop: 数据绑定名称
      * type: 表单类型
      * label: 表单左侧名称
      * placeholder: 为空默认显示
      * multiple: 是否多选
      * optionData: 子项的数据数组
      * rules：校验规则
      *
      *
      * linkage： 联动项目
      *   prop：联动哪项
      *   value：值相等时才显示
      *
      *
      * isShowDeleteBtn： 是否展示删除按钮
      *
      * */
      // 构造表单的原始数据集合
      originalCreateFormData: [],
      ruleForm: {
        cached: false,
        calls: false,
        phone: false,
        receive: true,
        receiveWait: 0,
        protectBack: 0,
        protect: 0,
        descRule: 0,
        cacheSize: 3000,
        receiveSize: 100,
        again: 1,
        size: 10,
        freeze: false,
      },
      rules: {},
      // selectCreateForm
      selectCreateForm: '',
      selectCreateFormOptionsArr: [
        {
          "name": "opportunity.possessId",
          "label": "唯一配置",
          "code": "possess",
          "type": "select"
        },
        {
          "name": "opportunity.mediatorId",
          "label": "渠道",
          "code": "mediator",
          "type": "select"
        },
        {
          "name": "opportunity.subjectName",
          "label": "项目",
          "code": "subject",
          "type": "select",
          "customConfig": {
            allowCreate: true,
            filterable: true,
            defaultFirstOption: true
          }
        },
        {
          "name": "opportunity.createTime",
          "label": "机会创建时间",
          "code": "dateTime",
          "type": "time"
        },
        {
          "name": "opportunity.haveTime",
          "label": "最后指派时间",
          "code": "dateTime",
          "type": "time"
        },
        {
          "name": "opportunity.intention",
          "label": "意向度",
          "code": "intention",
          "type": "intention"
        },
        {
          "name": "$.BGGHDHADDH",
          "label": "科目",
          "code": "BGGHDHADDH",
          "type": "select",
          "customConfig": {
            allowCreate: true,
            filterable: true,
            defaultFirstOption: true
          }
        },
        {
          "name": "$.BGFHIGEGCI",
          "label": "机会来源-分类",
          "code": "BGFHIGEGCI",
          "type": "select",
          "customConfig": {
            allowCreate: true,
            filterable: true,
            defaultFirstOption: true
          }
        },
        {
          "name": "$.ASDQWCZX",
          "label": "机会情况说明",
          "code": "ASDQWCZX",
          "type": "select-like",
          "customConfig": {
            allowCreate: true,
            filterable: true,
            defaultFirstOption: true
          }
        },
        {
          "name": "opportunity.dealAmount",
          "label": "成单金额",
          "code": "money",
          "type": "number"
        }
      ],
      ComparisonTable: {
        "time": {
          templateName: 'time',
          prop: '',
          type: 'combo',
          label: '',
          isShowDeleteBtn: true,
          comboList: [
            {
              prop: 'compare',
              type: 'select',
              placeholder: '请选择比较条件',
              multiple: false,
              optionData: [
                {label: '<', value: 'LT'},
                {label: '<=', value: 'LE'},
                {label: '>=', value: 'GE'},
                {label: '>', value: 'GT'}
              ]
            },
            {
              prop: 'compareType',
              type: 'select',
              placeholder: '请选择类型',
              multiple: false,
              optionData: [
                {label: '日期选择', value: 0},
                {label: '距离今日', value: 1}
              ]
            },
            {
              prop: 'date',
              type: 'datePicker',
              dateType: 'datetime',
              placeholder: '请选择日期',
              linkage: {
                prop: 'compareType',
                value: 0
              }
            },
            {
              prop: 'day',
              type: 'input',
              placeholder: '请填写距离今日多少天',
              linkage: {
                prop: 'compareType',
                value: 1
              }
            }
          ]
        },
        "select": {
          templateName: 'select',
          prop: '',
          type: 'select',
          label: '',
          placeholder: '',
          multiple: true,
          isShowDeleteBtn: true,
          optionData: []
        },
        "select-like": {
          templateName: 'select-like',
          prop: '',
          type: 'select',
          label: '',
          placeholder: '',
          multiple: true,
          isShowDeleteBtn: true,
          optionData: []
        },
        // 学习进度使用
        "intention": {
          templateName: 'intention',
          prop: '',
          type: 'combo',
          label: '',
          placeholder: '',
          isShowDeleteBtn: true,
          comboList: [
            {
              prop: 'compare',
              type: 'select',
              placeholder: '请选择类型',
              multiple: false,
              optionData: [
                {label: '包含', value: 'IN'},
                {label: '不包含', value: 'NOTIN'}
              ]
            },
            {
              prop: 'tag',
              type: 'select',
              placeholder: '请选择类型',
              multiple: true,
              optionData: []
            },
          ]
        },
        "number": {
          templateName: 'number',
          prop: '',
          type: 'combo',
          label: '',
          placeholder: '',
          isShowDeleteBtn: true,
          comboList: [
            {
              prop: 'compare',
              type: 'select',
              placeholder: '请选择比较条件',
              multiple: false,
              optionData: [
                {label: '=', value: 'EQ'},
                {label: '<', value: 'LT'},
                {label: '<=', value: 'LE'},
                {label: '>=', value: 'GE'},
                {label: '>', value: 'GT'},
                {label: '动态范围', value: 'BETWEEN'},
              ]
            },
            {
              prop: 'start',
              type: 'input',
              placeholder: ''
            },
            {
              prop: 'end',
              type: 'input',
              placeholder: '',
              linkage: {
                prop: 'compare',
                value: 'BETWEEN'
              }
            }
          ]
        },
      },

      originalCreateFormData2: [],
      ruleForm2: {},
      rules2: {},


      checkedDept: [],
      deptTree: [],
      id: ''
    };
  },
  computed: {
    // 是否展示表单项
    isShowFormItem() {
      return (item) => {
        if (item.hasOwnProperty('linkage')) {
          if (!item.linkage.prop) {
            return false
          }
          //
          if (this.ruleForm[item.linkage.prop] === item.linkage.value) {
            return true
          }
          return false
        }
        return true
      }
    }
  },
  async mounted() {

    this.id = this.$route.query?.id

    await this.initOriginalCreateFormData()

    this.initPage()
    this.getDeptTree()

    if (this.id) {
      await this.setFromData()
    }

    this.isCreatePage = false

  },
  methods: {
    async findTagsOptions() {
      const { data } = await tagsApi.options()
      if (data) {
        return data.map(item => {
          return {
            label: item.name,
            value: item.id
          }
        })
      }
      return []
    },
    async initOriginalCreateFormData() {
      const tagesArr = await this.findTagsOptions()

      // console.log(tagesArr)

      this.originalCreateFormData = [
        {
          prop: 'name',
          type: 'input',
          label: '按钮名称',
          placeholder: '请输入按钮名称',
          remarks: "管理名称,不可见",
          tooltipText: '管理名称,学习顾问不可见,其他配置可见名称',
          rules: [
            {required: true, message: '请输入按钮名称', trigger: 'blur'}
          ]
        },
        {
          prop: 'label',
          type: 'input',
          label: '按钮展示名称',
          placeholder: '请输入按钮展示名称',
          remarks: "按钮展示文字",
          tooltipText: '学习顾问实际可见的按钮显示名称',
          rules: [
            {required: true, message: '请输入按钮展示名称', trigger: 'blur'}
          ]
        },
        {
          prop: 'remarks',
          type: 'input',
          inputType: 'textarea',
          label: '按钮功能描述',
          placeholder: '请输入按钮功能描述',
          tooltipText: '备注/描述',
          rules: [
            {required: true, message: '请输入按钮功能描述', trigger: 'blur'}
          ],
          style: {
            width: '300px'
          }
        },
        {
          prop: 'first',
          type: 'radio',
          label: '机会是否被分配过',
          tooltipText: '筛选首咨数据还是库存数据',
          optionData: [
            {label: '首咨', value: true},
            {label: '库存', value: false}
          ],
          rules: [
            {required: true, message: '请选择', trigger: 'change'}
          ]
        },
        // {
        //   prop: 'cached',
        //   type: 'radio',
        //   label: '是否缓存',
        //   placeholder: '请选择',
        //   optionData: [
        //     {label: '不缓存', value: false},
        //     {label: '缓存', value: true}
        //   ],
        //   rules: [
        //     {required: true, message: '请选择', trigger: 'change'}
        //   ]
        // },
        // {
        //   prop: 'cacheSize',
        //   type: 'input',
        //   label: '缓存数量',
        //   placeholder: '请输入缓存数量',
        //   rules: [
        //     {required: true, message: '请输入缓存数量', trigger: 'blur'},
        //   ],
        //   // 联动项目
        //   linkage: {
        //     prop: 'cached',
        //     value: true
        //   }
        // },
        {
          prop: 'descRule',
          type: 'select',
          label: '机会创建时间排序',
          placeholder: '请选择机会创建时间排序',
          tooltipText: '排序方式,具体看选项说明',
          multiple: false,
          optionData: [
            {label: '正序(越晚创建的越先领取)', value: 0},
            {label: '倒叙(越早创建的越先领取)', value: 1},
            {label: '随机(所有满足条件随机抽取)', value: 2}
          ],
          rules: [
            {required: true, message: '请选择机会创建时间排序', trigger: 'change'}
          ]
        },
        {
          prop: 'poss',
          type: 'radio',
          label: '启用唯一配置限制',
          tooltipText: '如果唯一配置生效,则数据会取学习顾问有权限的数据和当前按钮配置的条件的数据的交集' +
              ';否则,如果不生效,则只按照当前按钮配置',
          optionData: [
            {label: '生效', value: true},
            {label: '不生效', value: false}
          ],
          rules: [
            {required: true, message: '请选择', trigger: 'change'}
          ]
        },
        {
          prop: 'tags',
          type: 'select',
          label: '标签',
          placeholder: '请选择标签',
          tooltipText: '选择标签',
          multiple: true,
          optionData: tagesArr,
          rules: []
        },
        {
          prop: 'size',
          type: 'input',
          label: '分页数量',
          placeholder: '请填写',
          tooltipText: '每次展示的数据条数',
          rules: [
            {required: true, message: '请输入分页数量间保护间隔', trigger: 'blur'},
          ]
        },
        {
          prop: 'protect',
          type: 'input',
          label: '创建保护时间(分钟)',
          placeholder: '请填写',
          tooltipText: '机会创建后,需要等待多久,可被本按钮展示出来',
          rules: [
            {required: true, message: '请输入机会创建时间保护间隔', trigger: 'blur'},
          ]
        },
        {
          prop: 'again',
          type: 'input',
          label: '操作保护时间(天)',
          placeholder: '请填写',
          tooltipText: '机会被(拨打、查看手机号)后,可被本按钮再次展示出来',
          rules: [
            {required: true, message: '请输入操作保护保护间隔', trigger: 'blur'},
          ]
        },
        {
          prop: 'receiveWait',
          type: 'input',
          label: '领取间隔时间(秒)',
          placeholder: '请填写',
          tooltipText: '用户领取后,需要等待多久后,才可以再次领取',
          rules: [
            {required: true, message: '请输入领取等待间隔', trigger: 'blur'},
          ]
        },
        {
          prop: 'protectBack',
          type: 'input',
          label: '回库间隔时间(天)',
          placeholder: '请填写',
          tooltipText: '机会被回库后,需要等待多久,才可被本按钮展示出来',
          rules: [
            {required: true, message: '请输入回库间隔时间', trigger: 'blur'},
          ]
        },
        {
          prop: 'receiveSize',
          type: 'input',
          label: '默认每日领取上限',
          placeholder: '请填写此按钮默认每日领取上限',
          tooltipText: '按钮每日的领取上限,另外可对不同学习顾问在学习顾问配置针对按钮单独配置领取上限',
          rules: [
            {required: true, message: '请填写此按钮默认每日领取上限', trigger: 'blur'},
          ]
        },
        {
          prop: 'phone',
          type: 'radio',
          label: '显示查看手机号按钮',
          optionData: [
            {label: '展示', value: true},
            {label: '隐藏', value: false}
          ],
          rules: [
            {required: true, message: '请选择', trigger: 'change'}
          ]
        },
        {
          prop: 'calls',
          type: 'radio',
          label: '显示拨打按钮',
          optionData: [
            {label: '展示', value: true},
            {label: '隐藏', value: false}
          ],
          rules: [
            {required: true, message: '请选择', trigger: 'change'}
          ]
        },
        {
          prop: 'receive',
          type: 'radio',
          label: '显示领取按钮',
          optionData: [
            {label: '展示', value: true},
            {label: '隐藏', value: false}
          ],
          rules: [
            {required: true, message: '请选择', trigger: 'change'}
          ]
        },
        {
          prop: 'freeze',
          type: 'radio',
          label: '显示冻结按钮',
          optionData: [
            {label: '展示', value: true},
            {label: '隐藏', value: false}
          ],
          rules: [
            {required: true, message: '请选择', trigger: 'change'}
          ]
        },
      ]
    },
    /*
    * 数据回显
    * */
    async setFromData(data = {}) {

      const _res = await receiveApi.infoReceive(this.id)
      // console.log(_res)

      data = _res.data || {}

      let _obj = Object.assign({}, data)
      delete _obj.children

      // 回显外层数据
      _obj.tags = data.tags?.split(',')?.map(Number) || []

      // 缓存数量不回显
      this.$nextTick(() => {
        this.ruleForm = Object.assign({}, _obj)
      })


      // 回显部门数据
      this.checkedDept = data.deptIds?.split(',') || []


      // 回显动态表单数据
      data.children?.map(async item => {
        const id = await this.addFormItem(item.name)

        let params = {}
        switch (item.type) {
          case 'time':
            params.compare = item.compare
            params.compareType = item.compareType
            item.compareType === 1 ? params.day = item.value : params.date = item.value
            break
          case 'select':
            params = item.value?.split(',') || []
            break
          case 'select-like':
            params = item.value?.split(',') || []
            break
          case 'number':
            params.compare = item.compare
            if (item.compare === 'BETWEEN') {
              params.start = item.value[0]
              params.end = item.value[1]
            } else {
              params.start = item.value
            }
            break

          case 'intention':
            params.compare = item.compare
            params.tag = item.value?.split(',') || []
            break
        }

        this.$nextTick(() => {
          this.ruleForm2[id] = params
        })


      })

      // console.log('最后构建的数据', this.ruleForm2)

    },
    getDeptTree(id) {
      possApi.findDeptTreeByPoss(id).then(res => {
        this.deptTree = res.data;
      })
    },
    /**
     * 根据code获取下拉框参数
     * @param code
     */
    async findOptions(code) {
      const res = await receiveApi.findOptions(code)
      const arr = res.data?.map(item => {
        item.value = String(item.value)
        return item
      }) || []
      return arr
    },
    /*
    * 构建最终数据结果
    *
    * 调用提交接口前 过滤并构造数据
    *
    * */
    createSubmitData() {
      // 构建动态表单的数据结果
      const childrenForm = []
      this.originalCreateFormData2.map(item => {

        const _res = {
          name: item.prop,
          label: item.label,
          type: item.templateName
        }

        switch (item.templateName) {
          case 'time':
            _res.compare = this.ruleForm2[item.id].compare
            _res.compareType = this.ruleForm2[item.id].compareType
            _res.value = _res.compareType === 1 ? this.ruleForm2[item.id].day : this.ruleForm2[item.id].date
            break
          case 'select':
            _res.compare = 'IN'
            _res.value = (this.ruleForm2[item.id] || []).join(',')
            break
          case 'select-like':
            _res.compare = 'LIKEIN'
            _res.value = (this.ruleForm2[item.id] || []).join(',')
            break
          case 'number':
            _res.compare = this.ruleForm2[item.id].compare
            _res.value = _res.compare === 'BETWEEN' ? [this.ruleForm2[item.id].start, this.ruleForm2[item.id].end] : this.ruleForm2[item.id].start
            break
          case 'intention':
            _res.compare = this.ruleForm2[item.id].compare
            _res.value = this.ruleForm2[item.id].tag.join(',')
        }

        childrenForm.push(_res)
      })
      // console.log(childrenForm)

      // 构建总的数据结果
      const form = Object.assign({}, this.ruleForm)
      form.children = childrenForm


      // 过滤tags数组数据
      form.tags = form.tags?.join(',') || null

      console.log(this.ruleForm)

      // 组合部门的数据结果
      const selectDeptForm = this.$refs.deptForm.getCheckedKeys() || []

      // console.log(selectDeptForm)
      form.deptIds = selectDeptForm.join(',')

      return form
    },
    async addFormItem(selectCreateForm) {
      const _id = guid()

      if (!selectCreateForm) {
        this.$message.warning('请选择表单项')
        return
      }

      const selectObj = this.selectCreateFormOptionsArr.filter(item => item.name === selectCreateForm)[0] || {}

      if (!this.ComparisonTable[selectObj.type]) {
        this.$message.warning('无当前表单对照数据')
        return;
      }


      // if (this.ruleForm2.hasOwnProperty(selectObj.name)) {
      //   this.$message.warning('请勿重复添加')
      //   return;
      // }


      let params = Object.assign({}, this.ComparisonTable[selectObj.type])

      if (selectObj.hasOwnProperty('customConfig')) {
        params = {...params, ...selectObj.customConfig}
      }

      params.prop = selectObj.name
      params.label = selectObj.label
      params.id = _id

      this.rules2[params.id] = params.rules || []

      // 如果是select 构建选项数据
      if (params.templateName === 'select' || params.templateName === 'select-like') {
        const arr = await this.findOptions(selectObj.code)
        // console.log(arr)
        params.optionData = arr
      }


      // 如果是select 构建选项数据
      if (params.templateName === 'intention') {
        const arr = await this.findOptions(selectObj.code)
        // console.log(arr)
        params.comboList[1].optionData = arr
      }


      this.originalCreateFormData2.push(params)

      return _id
    },
    initPage() {
      this.isCreatePage = true
      this.createRules()
    },
    // 创建rules
    createRules() {
      this.originalCreateFormData.map(item => {
        if (item.rules) {
          this.rules[item.prop] = item.rules
        }
      })
    },
    // 是否删除某个表单项目
    onDeleteFormItem(item) {
      this.originalCreateFormData2.map((_item, index) => {
        if (_item.prop === item.prop) {
          this.originalCreateFormData2.splice(index, 1)
        }
      })
    },
    submitForm(formName) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // console.log(this.createSubmitData())
          receiveApi.saveReceive(this.createSubmitData()).then(res => {
            // console.log(res)
            if (res.success) {
              this.$router.back()
              this.$message.success('保存成功')
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
}
</script>

<style scoped lang="less">
.custom--assembly-form {
  background-color: #FFFFFF;
  padding: 20px 30px;
  min-height: 100vh;
}
</style>
