import { render, staticRenderFns } from "./dynamicFormBuild.vue?vue&type=template&id=a99e8926&scoped=true&"
import script from "./dynamicFormBuild.vue?vue&type=script&lang=js&"
export * from "./dynamicFormBuild.vue?vue&type=script&lang=js&"
import style0 from "./dynamicFormBuild.vue?vue&type=style&index=0&id=a99e8926&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a99e8926",
  null
  
)

export default component.exports